import React from "react";
import {Link} from "gatsby";

import useDarkMode from "@accrosoft-ltd/accropress-theme-inspiration/src/components/use-dark-mode";

import WeducWebAttribution from "@accrosoft-ltd/accropress-websites-components/dist/esm/WeducWebAttribution";

const gatsbyConfig = require("@accrosoft-ltd/accropress-theme-inspiration/theme-config.js");

const gatsbyConfigSiteMetaData = gatsbyConfig.siteMetadata;

const Footer = (props) => {
  const footerWidgetData =
    props.gatsbyConfigPluginsAPOptions.siteSettings.widgetsPayload &&
    props.gatsbyConfigPluginsAPOptions.siteSettings.widgetsPayload.length > 0
      ? props.gatsbyConfigPluginsAPOptions.siteSettings.widgetsPayload.find(
          (o) => o.widgetID === "footer"
        )
      : props.gatsbyConfigPluginsAPOptions.siteSettings.theme &&
        props.gatsbyConfigPluginsAPOptions.siteSettings.theme
          .widgetsDefinition &&
        props.gatsbyConfigPluginsAPOptions.siteSettings.theme.widgetsDefinition.find(
          (o) => o.widgetID === "footer"
        );

  const darkMode = useDarkMode(false);

  return (
    <footer>
      <div className="waves">
        <div className="line line-1">
          <div className="wave four wave1">
            <svg
              width="1786"
              height="409"
              viewBox="0 0 1786 409"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.17916C1.22413 0.835877 0 0.223938 1.22413 0.835878C246.049 31.4329 282.773 117.105 573.503 260.911C864.233 404.716 1491.6 352.702 1785.39 144.642C1785.39 150.761 1785.39 196.657 1785.39 196.657C1785.39 196.657 992.766 631.134 503.116 257.851C252.17 55.9105 31.8273 19.1941 0 8.17916Z"
                fill="url(#paint0_linear)"
                fill-opacity="0.8"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="25.8309"
                  y1="257.184"
                  x2="1778.5"
                  y2="75.2166"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#004087" />
                  <stop offset="0.197917" stop-color="#e0fbff" />
                  <stop offset="0.520833" stop-color="#c9ebf2" />
                  <stop offset="0.8149" stop-color="#000" />
                  <stop offset="0.9997" stop-color="#000" />
                  <stop offset="1" stop-color="#000" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="line line-2">
          <div className="wave one wave2">
            <svg
              width="1786"
              height="409"
              viewBox="0 0 1786 409"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.17916C1.22413 0.835877 0 0.223938 1.22413 0.835878C246.049 31.4329 282.773 117.105 573.503 260.911C864.233 404.716 1491.6 352.702 1785.39 144.642C1785.39 150.761 1785.39 196.657 1785.39 196.657C1785.39 196.657 992.766 631.134 503.116 257.851C252.17 55.9105 31.8273 19.1941 0 8.17916Z"
                fill="url(#paint0_linear)"
                fill-opacity="0.8"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="25.8309"
                  y1="257.184"
                  x2="1778.5"
                  y2="75.2166"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#D94438" />
                  <stop offset="0.197917" stop-color="#bc362d" />
                  <stop offset="0.520833" stop-color="#2b2d2e" />
                  <stop offset="0.8149" stop-color="#000" />
                  <stop offset="0.9997" stop-color="#000" />
                  <stop offset="1" stop-color="#000" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="line line-3">
          <div className="wave two wave3">
            <svg
              width="1786"
              height="350"
              viewBox="0 0 1786 350"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 64.2537C0 64.2537 301.135 12.2388 594.925 201.94C910.138 406.94 1412.03 198.881 1476.3 171.343C1540.56 143.806 1785.39 0 1785.39 0V48.9552C1785.39 48.9552 1537.5 208.06 1482.42 232.537C1427.33 257.015 1017.25 489.552 539.84 220.299C233.808 24.4776 0 118.716 0 118.716V64.2537Z"
                fill="url(#paint0_linear)"
                fill-opacity="0.6"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="14.6907"
                  y1="260.053"
                  x2="1793.52"
                  y2="75.3697"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#D94438" />
                  <stop offset="0.197917" stop-color="#bc362d" />
                  <stop offset="0.520833" stop-color="#2b2d2e" />
                  <stop offset="0.8149" stop-color="#000" />
                  <stop offset="0.9997" stop-color="#000" />
                  <stop offset="1" stop-color="#000" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {footerWidgetData &&
            footerWidgetData.children.map((widget, i) => (
              <>
                {widget.id === "other-resources" ? (
                  <div className="col-12 col-6-m other-resources-footer">
                    <div className="header">{widget.name}</div>
                    {widget.children && widget.children[0] ? (
                      <ul>
                        {widget.children.map((widgetChild) => (
                          <>
                            {widgetChild.map((link, i) => (
                              <li>
                                <span className="fas fa-angle-right"></span>
                                <a href={link.value}>{link.text}</a>
                              </li>
                            ))}
                          </>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        {widget.columns[0] &&
                          widget.columns[0].map((link, i) => (
                            <li>
                              <span className="fas fa-angle-right"></span>
                              <a href={link.value}>{link.text}</a>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ) : widget.id === "footer-contact-details" ? (
                  <div className="col-12 col-3-m">
                    <div className="header">{widget.name}</div>
                    <div className="address">
                      <span className="addressName">
                        {widget.children && widget.children[0]
                          ? widget.children[0][0].value ||
                            widget.children[0][0].default
                          : widget.columns[0].value ||
                            widget.columns[0].default}
                      </span>
                      <p>
                        {widget.children && widget.children[0]
                          ? widget.children[0][1].value ||
                            widget.children[0][1].default
                          : widget.columns[0].value ||
                            widget.columns[1].default}
                      </p>
                      <p>
                        {widget.children && widget.children[0]
                          ? widget.children[0][2].value ||
                            widget.children[0][2].default
                          : widget.columns[0].value ||
                            widget.columns[2].default}
                      </p>
                    </div>
                    <div className="contact">
                      <div>
                        <span className="fas fa-mobile-alt"></span> Tel:{" "}
                        <a
                          href={`tel:${
                            widget.children && widget.children[0]
                              ? widget.children[0][3].value ||
                                widget.children[0][3].default
                              : widget.columns[0].value ||
                                widget.columns[3].default
                          }`}
                        >
                          {widget.children && widget.children[0]
                            ? widget.children[0][3].value ||
                              widget.children[0][3].default
                            : widget.columns[0].value ||
                              widget.columns[3].default}
                        </a>
                      </div>
                      <div>
                        <span className="far fa-envelope"></span>{" "}
                        <a
                          href={`mailto:${
                            widget.children && widget.children[0]
                              ? widget.children[0][4].value ||
                                widget.children[0][4].default
                              : widget.columns[0].value ||
                                widget.columns[4].default
                          }`}
                        >
                          {widget.children && widget.children[0]
                            ? widget.children[0][4].value ||
                              widget.children[0][4].default
                            : widget.columns[0].value ||
                              widget.columns[4].default}
                        </a>
                      </div>
                      <div className="social-links">
                        {widget.children[0][5] && widget.children[0][5].value && (
                          <a
                            href={widget.children[0][5].value}
                            target="_blank"
                            class="social"
                          >
                            <span class="fab fa-instagram"></span>
                          </a>
                        )}
                        {widget.children[0][6] && widget.children[0][6].value && (
                          <a
                            href={widget.children[0][6].value}
                            target="_blank"
                            class="social"
                          >
                            <span class="fab fa-facebook-f"></span>
                          </a>
                        )}
                        {widget.children[0][7] && widget.children[0][7].value && (
                          <a
                            href={widget.children[0][7].value}
                            target="_blank"
                            class="social"
                          >
                            <span class="fab fa-twitter"></span>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 col-3-m"></div>
                )}
              </>
            ))}

          <div className="col-12 col-3-m">
            <div className="toggle-mode">
              <div>High Visibility Version:</div>
              <span class="fal fa-sun"></span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={darkMode.value}
                  onChange={darkMode.toggle}
                />
                <span className="slider round"></span>
              </label>
              <span class="fal fa-moon"></span>
            </div>

            <div className="websites-by-weduc">
              <WeducWebAttribution
                campaignName={gatsbyConfigSiteMetaData.title}
              />
            </div>
            <div className="copyright">
              &copy; {gatsbyConfigSiteMetaData.title}. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
